import React from "react";

export default function Function() {

    return (
        <div className="c-light-2">
            <h1>In Construction</h1>
            <p>
                This feature has not yet been implemented.
            </p>
            <p>
                For more information, go to the 
                <a className="t-dec-none c-light-0 c-light-4-h" href="#/library/changelog"> changelog</a>
                , under the unreleased section, and see when will this feature be implemented.
            </p>
        </div>
    );
}